import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout.component';
import Footer from '../sections/footer/footer.component';
import TextSection from '../sections/subpageSections/blogSubpage/textSection.component';
import SubpageHero from '../components/subpageHero/subpageHero.component';
import SEO from '../components/seo.component';
import { LANG } from '../lang/lang';

const BlogPage = ({ data }) => {
  const { edges } = data.allMdx;
  return (
    <Layout>
      <SEO title={LANG.PL.blogSubpage.seoTitle} />
      <SubpageHero title={LANG.PL.blogSubpage.subTitle} subTitle={LANG.PL.blogSubpage.title}>
        <TextSection articles={edges} />
        <Footer />
      </SubpageHero>
    </Layout>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query {
    allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          slug
          frontmatter {
            title
            slug
            intro
            date
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
