import styled from "styled-components";

export const StyledTextWrapper = styled.section`
  margin: 68px 0 100px 0;
  @media only screen and (max-width: 768px) {
    margin: 0px 0 100px 0;
  }
`;

export const StyledTextContent = styled.div`
  padding: 0 149px;
  @media only screen and (max-width: 768px) {
    padding: 0 50px;
  }
  @media only screen and (max-width: 425px) {
    padding: 0 20px;
  }
`;
