import styled from 'styled-components';

export const StyledArticleWrapper = styled.div`
  height: fit-content;
  max-width: 1364px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 20px;

  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.04);
  margin-bottom: 70px;
  padding: 60px;
  display: flex;

  &:last-child {
    margin-bottom: 100px;
  }
  @media only screen and (max-width: 1260px) {
    flex-direction: column;
    align-items: center;
  }
  @media only screen and (max-width: 425px) {
    padding: 60px 10px;
  }
`;

export const StyledArticleImage = styled.img`
  border-radius: 20px;
  height: auto;
  min-height: 400px;
  width: 40%;
  object-fit: cover;
  @media only screen and (max-width: 1260px) {
    width: 80%;
    margin-bottom: 20px;
    max-height: 400px;
    object-fit: none;
  }
  @media only screen and (max-width: 768px) {
    width: auto;
    height: 30%;
  }
  @media only screen and (max-width: 425px) {
    max-width: 250px;
    max-height: 200px;
  }
`;

export const StyledArticleContent = styled.div`
  height: 100%;
  padding: 0 40px 20px 40px;
  @media only screen and (max-width: 1260px) {
    text-align: center;
  }
  @media only screen and (max-width: 1024px) {
    padding: 0;
  }
`;

export const StyledArticleDate = styled.p`
  margin: 10px 0;
  color: ${({ theme }) => theme.colors.black_2};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  @media only screen and (max-width: 425px) {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;

export const StyledArticleDesc = styled.p`
  max-height: 180px;
  max-width: 90%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.black_3};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  @media only screen and (max-width: 1260px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 425px) {
    font-size: ${({ theme }) => theme.fontSizes.xsmall};
  }
`;

export const StyleButtonBox = styled.div`
  display: flex;
  align-items: center;
  height: 140px;
  width: 300px;
  @media only screen and (max-width: 1260px) {
    margin: 0 auto;
    justify-content: center;
  }
  @media only screen and (max-width: 768px) {
    height: 110px;
  }
  @media only screen and (max-width: 425px) {
    height: 80px;
    width: 200px;
  }
`;
