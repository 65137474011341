import React from 'react';
import {
  StyleButtonBox,
  StyledArticleContent,
  StyledArticleDate,
  StyledArticleDesc,
  StyledArticleImage,
  StyledArticleWrapper,
} from './articlesList.style';
import { StyledBoldTitle } from '../../../../utils/styledComponents/boldTitle.style';
import Button from '../../../../components/button/button.component';
import { LANG } from '../../../../lang/lang';

type ArticlesListPropsType = {
  title: string;
  date: string;
  desc: string;
  slug: string;
  image: string;
};

const ArticlesList = ({ title, date, desc, slug, image }: ArticlesListPropsType): JSX.Element => {
  return (
    <StyledArticleWrapper>
      <StyledArticleImage src={image} />
      <StyledArticleContent>
        <StyledBoldTitle>{title}</StyledBoldTitle>
        <StyledArticleDate>{date}</StyledArticleDate>
        <StyledArticleDesc>{desc}</StyledArticleDesc>
        <StyleButtonBox>
          <Button yellow={false} type="link" to={`/blog/${slug}`}>
            {LANG.PL.blogSubpage.button}
          </Button>
        </StyleButtonBox>
      </StyledArticleContent>
    </StyledArticleWrapper>
  );
};

export default ArticlesList;
