import React from 'react';
import { StyledTextContent, StyledTextWrapper } from './textSection.style';
import { StyledCenter } from '../../../utils/styledComponents/center.style';
import ArticlesList from './articlesList/articlesList.component';
import { format, parseISO } from 'date-fns';
import { pl } from 'date-fns/locale';

type FixedItem = {
  height: number;
  width: number;
  src: string;
  srcSet: string;
  tracedSVG: string;
};
type Fixed = {
  fixed: FixedItem;
};

type Frontmatter = {
  date: string;
  intro: string;
  slug: string;
  title: string;
  featuredImage: {
    childImageSharp: Fixed;
  };
};

type ArticlesData = {
  id: string;
  slug: string;
  frontmatter: Frontmatter;
};

type ArticlesItem = {
  [name: string]: ArticlesData;
};

type TextSectionPropsType = {
  articles: ArticlesItem[];
};

const TextSection = ({ articles }: TextSectionPropsType): JSX.Element => {
  const renderArticles = articles.map((el) => {
    return (
      el.node.frontmatter.date < new Date().toISOString() && (
        <ArticlesList
          key={el.node.id}
          title={el.node.frontmatter.title}
          date={format(parseISO(el.node.frontmatter.date), 'dd MMMM yyyy', {
            locale: pl,
          })}
          desc={el.node.frontmatter.intro}
          slug={el.node.slug}
          image={el.node.frontmatter.featuredImage.childImageSharp.fluid.src}
        />
      )
    );
  });
  return (
    <StyledTextWrapper>
      <StyledCenter>
        <StyledTextContent>{renderArticles}</StyledTextContent>
      </StyledCenter>
    </StyledTextWrapper>
  );
};

export default TextSection;
